<template>
  <div class="wrap">
      <van-cell :title="list.cus_name" />
      <van-cell title="证件号码" :value="list.card_id"/>
      <van-cell title="手机号" :value="list.mobile" />
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button round block type="danger" @click="onSubmit">
        账号解绑
        </van-button>
      </van-col>
  </div>
</template>
<script>
  import moment from 'moment';
  import { Dialog,Toast } from 'vant';
  import {accountInfo,accountUnbind,getOpenId} from '@/server'
  import {setCookie,getCookie,domainUrl} from './../lib/utils'
  export default {
  name: 'account',
  data() {
    return {
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      openId:getCookie('openId') ||this.$store.state.openId,
      code:this.$route.query.code || '',
      list:{}
    };
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'account2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'account2') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  created(){
    this.$store.commit('alertModal', { modalBtnCancel:false,fn:null});
    // this.getDetail();
    //如果有openid
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/account' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        let uiCode = encodeURIComponent(domainUrl+'/account');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/account' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    } 
  },
  methods: {
    getDetail(){
      //信息返显 openid, access_token, card_id
      const params = {
        openid:this.openId,
        access_token:this.accessToken,
        card_id:this.cardId
      }
      accountInfo(params).then(res=>{
        if(res.wx_code=='0'){
          this.list = res;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    onSubmit() {
      //openid, access_token, card_id
      const params = {
        openid:this.openId,
        access_token:this.accessToken,
        card_id:this.cardId
      }
      Dialog.confirm({
        title: '账号解绑',
        message: '解绑后将不能再查看合同信息，确定是否解绑',
      })
      .then(() => {
          // on confirm
        accountUnbind(params).then(res =>{
          if(res.wx_code =='0'){
            Toast({
              message: '解绑成功！',
              icon: require('@/assets/img/bindSuccess.png'),
              duration:'3000'
            });
            localStorage.removeItem('accessToken');
            // setCookie('accessToken','',-1);
            this.$store.commit('accessToken', '');

            localStorage.removeItem('openId');
            // setCookie('openId','',-1);
            this.$store.commit('openId', '');

            localStorage.removeItem('cardId');
            // setCookie('cardId','',-1);
            this.$store.commit('cardId', '');

            localStorage.removeItem('bindStatus');
            // setCookie('bindStatus','',-1);
            this.$store.commit('bindStatus', '');
            this.$router.push({path: '/personalCenter'});
            // window.WeixinJSBridge.call('closeWindow') 
          }else{
            Toast({
              message: '解绑失败！',
              icon: require('@/assets/img/bindError.png'),
              duration:'3000'
            });
          }
        })
      })
      .catch(() => {
          // on cancel
      });
    },
  },
};
</script> 
<style scoped>
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
}
.tips p{
    margin: 6px 15px;
}
.van-cell{
  font-size: 15px !important;
  padding: 15px;
}
.block {
  display: inline-block;
  padding: 5px 28px;
  font-size: 16px;
  width: 22px;
  color: #ee0a24;
  text-align: center;
  border: 1px solid #ee0a24;
  border-radius: 3px;
  /* background-color: #ee0a24; */
}
</style>
<style>